import React, { useContext } from "react"
import { Link, graphql } from "gatsby"
import * as R from "ramda"

import searchBoxConfiguration from "../data/search-box-configuration.json"

import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

import pageLinks from "../data/links-404-page"

import SearchContext from "../contexts/SearchContext"
import queryString from 'query-string'

import Box from "@material-ui/core/Box"

import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"

import Layout from "../components/layout"
import SEO from "../components/SEO"

import colors from "../components/theme/colors"
import typography from "../components/theme/typography"
import constants from "../components/theme/constants"

import { getFiltersFromURL } from "../utils/urlSearchFilters"

/*  This template handles all search result/landing pages:
    - For landing pages (which are SSR results) the content will be 
      applied via the pageContext when the template is rendered by Gatsby build.
      The pages that are SSR are determined via data input in Gatsby node.

    - For dynamic searchs (criteria set by the user) - the content will be determined 
      by the URL path and use the Reach router to update and parse the tempalte URLs.
      The dynamic search pages are created for the base URL type (e.g. /toimitilat/) 
      and parse everything else in the URL to make the search criteria.

    See notes in gatsby-node.js on how the SSR & Reach router paths are configured for the app. 

    Other than those aspects, the functionality of the landing/search pages is identical as 
    this template handles the filtering of search results. Plus some other
*/

const useStyles = makeStyles(theme => ({
  introDescription: {
    color: colors.base.white,
    margin: "1rem auto 1.5rem",
    textAlign: "center",
    maxWidth: "800px",
  },
  resultsContainer: {
    margin: "1rem auto 3rem",
  },
  contentContainer: {
    background: colors.grey["-"],
    paddingTop: "1rem",
    paddingBottom: "2.5rem",
  },
  contentHeadings: {
    marginTop: "1em",
    marginBottom: "0.5em",
    textTransform: "uppercase",
  },
  contentText: {
    marginBottom: "1.25em",
  },
  root: {
    background: constants.hero.background,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  imageOverlay: {
    background: "rgba(0,0,0,0.25)",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    minHeight: "560px",
  },
  content: {
    maxWidth: constants.heading.maxWidth,
  },
  headlineText: {
    ...typography.headline,
    textAlign: "left",
  },
  bodyText: {
    ...typography.intro,
    fontWeight: 400,
    textShadow: typography.headline.textShadow,
    color: colors.base.white,
  },
  linkContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    alignItems: "flex-start",
    "@media (min-width: 600px)": {
      flexDirection: "row",
    },
  },
  linkItem: {},
  link: {
    ...typography.intro,
    fontWeight: 400,
    textShadow: typography.headline.textShadow,
    color: colors.base.white,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))

const NotFoundPage = (props) => {

  const {data, location} = props;

  let type = undefined;
  let city = undefined;
  let area = undefined;

  const { urlPathContext, queryParams } = getFiltersFromURL(
    location
  )

  const useExistingFilters = false;

  const searchContext = useContext(SearchContext)
  const {
    setResultPage,
  } = searchContext
  if(typeof window !== 'undefined'){
    // Save page attribute from URL to search context resultPage on client
    const pageAttribute = Number(R.path(['page'], queryString.parse(R.path(["location", "search"], window))));
    pageAttribute ? setResultPage(pageAttribute) : setResultPage(1);
  }

  // If page is not SSR, take the type, city, area from the path

  type = urlPathContext.type
  city = urlPathContext.city
  area = urlPathContext.area
  

  /*
  Next decide how the filters for the paghe should be defined:

  - If the path has a query parameter "f=true" then use the existing filters in the context 
  as the user is navigating the site whilst searching. In the case of this page rendering, do 
  nothing as the filters are already saved in the SearchContext.

  - If this query parameter is not set, then the user has clicked or followed a directly URL link 
  to the search page, so reset the filters based on the page path.

  FYI: There is a potential here to have more complex behaviour on how the page interacts with existing 
  filters & with additional query parameters, but that is for future design :)

  */
  if (!useExistingFilters) {
    // Grab any extra query params for the filter then
    // force refresh and updated of filters
    searchContext.updateFilters(
      { type, city, area },
      {
        ...(queryParams.smin && { sizeMin: queryParams.smin }),
        ...(queryParams.smax && { sizeMax: queryParams.smax }),
        ...((queryParams.wmin, queryParams.wmax)
          ? { workspaces: { min: queryParams.wmin, max: queryParams.wmax } }
          : queryParams.wmin && !queryParams.wmax
          ? { workspaces: { min: queryParams.wmin } }
          : !queryParams.wmin && queryParams.wmax
          ? { workspaces: { max: queryParams.wmax } }
          : {}),
      },
      true
    )
  }

  /* 404 */

  const headline = "404"
  const intro = "Hups! Sivua ei löytynyt."
  const body =
    "Kirjoitithan osoitteen oikein? Tässä on muutama hyödyllinen linkki";

  const backgroundImageFluid = R.path(["page404", "image", "gatsbyImageData"], data)

  const classes = useStyles()

  const pageContent = (
    <Container className={classes.container}>
      <Box className={classes.content}>
        <h1 className={classes.headlineText}>{headline}</h1>
        <h2 className={classes.headlineText}>{intro}</h2>
        <p className={classes.bodyText}>{body}</p>
        <List className={classes.linkContainer} component="nav">
          {pageLinks.map((page, index) => (
            <ListItem key={index} className={classes.linkContainer}>
              <Link className={classes.link} to={page.to}>
                {page.text}
              </Link>
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  )

  const convimage = getImage(backgroundImageFluid)
  const bgImage = convertToBgImage(convimage)

  const cont = backgroundImageFluid ? (
    <React.Fragment>
      <SEO title="404: Not found" />
      <BackgroundImage
        Tag="div"
        className={classes.root}
        {...bgImage}
        backgroundColor={colors.grey[2]}
      >
        <Box className={classes.imageOverlay}>{pageContent}</Box>
      </BackgroundImage>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <SEO title="404: Not found" />
      <Box>{pageContent}</Box>
    </React.Fragment>
  );

  // Filter out all types that do not match config
  const hasType = n => n.type === type;
  const knownType = type === 'toimitilat' || !R.isEmpty(R.filter(hasType, searchBoxConfiguration.premisesType));

  (!city || !knownType) && searchContext.setHeroLoaded(true);

  return (
    <Layout>
    { (searchContext.heroLoaded) &&
      <React.Fragment>
        {cont}
      </React.Fragment>
    }
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query NotFoundPageQuery {
    page404: contentfulSiteGlobal {
      image {
        gatsbyImageData(
          formats: [AUTO, WEBP],
          layout: CONSTRAINED, 
          width: 1200, 
          quality: 30,
        )
      }
    }
    defaultHero: contentfulAsset {
      gatsbyImageData(
        formats: [AUTO, WEBP],
        layout: CONSTRAINED, 
        width: 800, 
        quality: 90,
      )
    }
    contentfulEtusivu(activeVersion: { eq: "Kyllä" }) {
      newsecInfoHeading
      newsecInfoText {
        newsecInfoText
      }
      newsecInfoBalls {
        metric
        description
        icon {
          gatsbyImageData(
            formats: [AUTO, WEBP],
            layout: CONSTRAINED, 
            width: 150, 
            quality: 80,
          )
        }
      }
      newsecAboutHeading
      newsecAboutText {
        newsecAboutText
      }
      newsecAboutLink
      newsecAboutBackgroundImage {
        gatsbyImageData(
          formats: [AUTO, WEBP],
          layout: CONSTRAINED, 
          width: 1200, 
          quality: 30,
        )
      }
    }
  }
`
